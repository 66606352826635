import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~7],
		"/examples": [8,[2]],
		"/examples/dashboard": [9,[2]],
		"/examples/datatable": [10,[2]],
		"/examples/icons": [11,[2]],
		"/examples/login": [~12,[2]],
		"/onboarding": [13],
		"/privacy_policy": [14],
		"/terms_of_service": [15],
		"/third_party_agreement": [16],
		"/waitlist": [17],
		"/workflows": [~18,[3]],
		"/workflows/[workflowid]": [19,[3,4]],
		"/workflows/[workflowid]/dashboards": [20,[3,4,5]],
		"/workflows/[workflowid]/dashboards/governance": [22,[3,4,5]],
		"/workflows/[workflowid]/dashboards/personnel": [23,[3,4,5]],
		"/workflows/[workflowid]/dashboards/tieout": [24,[3,4,5]],
		"/workflows/[workflowid]/dashboards/[checkid]": [21,[3,4,5]],
		"/workflows/[workflowid]/documents": [25,[3,4,6]],
		"/workflows/[workflowid]/parties": [26,[3,4]],
		"/workflows/[workflowid]/parties/[entityid]": [27,[3,4]],
		"/workflows/[workflowid]/reports": [28,[3,4]],
		"/workflows/[workflowid]/settings": [29,[3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';